$breakpoint-xs: 400px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin media($media) {
  @if $media == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $media == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if $media == xxl {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
  }
}
@mixin flex($justify) {
  display: flex;
  justify-content: $justify;
  align-items: center;
}
@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: ($columns);
  grid-gap: ($gap);
}
@mixin bg-center() {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
