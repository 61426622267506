@import "./Mixins.scss";
.hero {
  @include grid(1fr 1fr, 30px);
  margin: 80px 0;

  &__container {
    position: relative;
  }
  &__text {
    -webkit-text-stroke: 2px var(--primary-color);
    color: transparent;
    margin-left: 60px;
    &--one {
      font-size: 14.5rem;
    }
    &--two {
      font-size: 14.5rem;
      margin-top: -90px;
    }
    &--three {
      font-size: 14.5rem;
      margin-top: -100px;
    }
  }
  &__slogan {
    font-size: 3rem;
    font-weight: 600;
    margin-left: 70px;
  }
  &__button {
    position: absolute;
    right: 0;
    background: transparent;
    font-size: 2.2rem;
    border: none;
    color: var(--primary-color);
    letter-spacing: 2px;
  }
  &__box {
    position: relative;
    @include flex(center);
  }
  &__image {
    width: 85%;
    max-width: 600px;

    &--circleOne {
      position: absolute;

      width: 200px;
      z-index: -1;
      left: -25px;
      top: 40px;
    }
    &--circleTwo {
      position: absolute;
      max-width: 220px;
      z-index: 1;
      right: 50px;
      bottom: 30px;
    }
  }
}
.intro {
  @include grid(1fr 1fr, 30px);
  width: 100%;
  overflow: hidden;
  &__container {
    position: relative;
  }
  &__image {
    width: 95%;
    max-width: 360px;
    margin: auto;
    @include flex(center);
    &--circleOne {
      position: absolute;
      max-width: 300px;
      z-index: -1;
      right: 40px;
      top: 80px;
    }
    &--circleTwo {
      position: absolute;
      max-width: 300px;
      z-index: -1;
      left: 60px;
      bottom: 100px;
    }
  }
  &__text {
    @include flex(center);
    flex-direction: column;
    margin: 50px;
  }
  &__subtitle {
    font-size: 3rem;
    color: var(--primary-color);
    letter-spacing: 5px;
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
  }
  &__paragraph {
    font-family: Montserrat;
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 300;
  }
}
.text {
  @include bg-center();

  background-image: url("../assets/slide-text.png");
  height: 350px;
  margin: 150px 0;
  &__container {
    @include flex(center);
    padding: 90px 0;
  }
  &__paragraph {
    font-size: 3.5rem;
    background: rgba(255, 255, 255, 0.776);
    padding: 60px 40px;
    letter-spacing: 1px;
    text-align: center;

    &--color {
      background: rgba(255, 255, 255, 0);
      color: var(--primary-color);
      margin-left: 10px;
    }
  }
}
.services {
  &__section {
    @include grid(1fr 2fr, 30px);
    margin: 90px 0;
  }
  &__text {
    position: relative;
    text-align: right;
  }
  &__title {
    position: absolute;
    left: 0;
    width: 130%;
    height: 120px;
    color: white;
    background-color: var(--primary-color);
    font-size: 4rem;
    letter-spacing: 2px;
    margin: 40px 0;
    font-weight: 600;
    padding: 30px;
  }
  &__image {
    width: 100%;
    max-width: 750px;
  }
  &__container {
    @include grid(1fr 1fr 1fr 1fr, 70px);
    margin: 60px;
  }
  &__cards {
    width: 240px;
  }
  &__box {
    @include grid(2fr 1fr, 10px);
  }
  &__subtitle {
    font-size: 2.3rem;
    font-weight: 400;
    line-height: 2.9rem;
    max-width: 170px;
    margin-top: 20px;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;
  }
  &__button {
    background: none;
    border: none;
    margin-top: 30px;
    width: 25px;
    cursor: pointer;
  }
  &__close {
    @include flex(flex-end);
    margin-top: -45px;
    margin-right: 15px;
  }
  &__paragraph {
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 40px;
    padding: 0 30px 0 0;
  }
}
.about {
  @include grid(1fr 1fr, 30px);
  margin: 150px 0;
  &__container {
    margin: 0 50px;
    position: relative;
  }

  &__image {
    @include flex(center);
    max-width: 600px;
    width: 90%;

    &--circleOne {
      position: absolute;
      width: 250px;
      z-index: -1;
      left: 80px;
      top: 30px;
    }
    &--circleTwo {
      position: absolute;
      width: 100px;
      z-index: -1;
      top: 150px;
      right: 140px;
    }
  }
  &__text {
    @include flex(center);
    flex-direction: column;
    width: 500px;
  }
  &__title {
    font-size: 3.6rem;
    text-align: left;
    width: 100%;
    letter-spacing: 3px;
    margin-bottom: 10px;
  }
  &__subtitle {
    font-size: 2.6rem;
    font-weight: 300;
    color: var(--primary-color);
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
  }
  &__paragraph {
    font-family: Montserrat;
    font-size: 1.7rem;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 10px;
  }
}
.features {
  @include grid(1fr 1fr 1fr, 0px);
  $root: &;
  margin: 130px 0;
  &__box {
    @include bg-center();
    height: 500px;
    position: relative;
    overflow: hidden;
    &--objectives {
      background-image: url("../assets/object-img.png");
    }
    &--experience {
      background-image: url("../assets/experence-img.png");
    }
    &--professional {
      background-image: url("../assets/profes-img.png");
    }
    &:hover {
      #{$root}__title {
        left: -100%;
      }
      #{$root}__text {
        opacity: 1;
      }
    }
  }
  &__title {
    position: absolute;
    background-color: white;
    bottom: 0;
    margin: 20px 0;
    padding: 15px 60px;
    font-size: 2.4rem;
    transition: all 0.5s linear;
    left: 0;
  }
  &__text {
    @include flex(center);
    flex-direction: column;
    background-color: white;
    margin: 60px;
    padding: 0 30px;
    height: 400px;
    transition: all 0.5s linear;
    opacity: 0;
  }
  &__subtitle {
    font-size: 2.2rem;
    color: var(--primary-color);
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
  }
  &__paragraph {
    font-family: Montserrat;
    font-size: 1.5rem;
    line-height: 20px;
  }
}
.contact {
  @include grid(1fr 1fr, 30px);
  margin: 90px 0;
  &__form {
    @include flex(center);
    flex-direction: column;
  }
  &__title {
    font-size: 2.2rem;
    font-weight: 300;
    margin-bottom: 30px;
  }
  &__list {
    list-style: none;
  }
  &__item {
    margin-bottom: 20px;
    &--button {
      text-align: center;
    }
  }
  &__input {
    border: 1px solid #b5b5b5;
    padding: 10px;
    width: 500px;
    border-radius: 10px;
    &::placeholder {
      font-size: 1.3rem;
    }
  }
  &__button {
    cursor: pointer;
    margin: 20px 0;
    background-color: var(--primary-color);
    border-radius: 20px;
    padding: 12px;
    width: 300px;
    border: none;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    &:hover {
      background-color: white;
      color: var(--primary-color);
    }
  }
  &__container {
    position: relative;
    @include flex(center);
  }
  &__image {
    width: 500px;
    &--circleOne {
      position: absolute;
      width: 120px;
      z-index: -1;
      right: 60px;
      top: 40px;
    }
    &--circleTwo {
      position: absolute;
      width: 250px;
      z-index: -1;
      bottom: 130px;
      right: 40px;
    }
  }
}
@include media(xl) {
  .hero {
    &__text {
      &--one {
        font-size: 13.5rem;
      }
      &--two {
        font-size: 13.5rem;
        margin-top: -90px;
      }
      &--three {
        font-size: 13.5rem;
        margin-top: -100px;
      }
    }
  }
  .intro {
    margin: 70px 0;
  }

  .services {
    &__container {
      grid-template-columns: 1fr 1fr;
      margin: 20px 60px;
    }
    &__section {
      grid-template-columns: 1fr;
      margin: 0;
    }
    &__title {
      width: 100%;
      position: relative;
      text-align: center;
      height: auto;
    }
    &__image {
      display: none;
    }
  }

  .contact {
    grid-template-columns: 1fr 1fr;
    &__container {
      text-align: center;
    }
    &__input {
      max-width: 500px;
    }
  }
}

@include media(lg) {
  .hero {
    &__text {
      &--one {
        font-size: 10.5rem;
      }
      &--two {
        font-size: 11.5rem;
        margin-top: -60px;
      }
      &--three {
        font-size: 11.5rem;
        margin-top: -70px;
      }
    }
  }
  .about {
    @include grid(1fr, 30px);
    @include flex(center);
    flex-direction: column;
    &__text {
      width: auto;
      padding: 23px;
      margin: 0 14px;
    }
  }
  .features {
    grid-template-columns: 1fr;
  }
  .services {
    &__container {
      @include grid(1fr 1fr, 30px);
      margin-left: 130px;
    }

    &__subtitle {
      font-size: 2.5rem;
    }
  }
  .contact {
    grid-template-columns: 1fr;
    &__container {
      text-align: center;
    }
    &__input {
      max-width: 500px;
    }
  }
}

@include media(md) {
  .hero {
    @include grid(1fr, 10px);
    margin: 50px 0;
    &__text {
      &--one {
        font-size: 15rem;
      }
      &--two {
        font-size: 15rem;
        margin-top: -90px;
      }
      &--three {
        font-size: 15rem;
        margin-top: -100px;
      }
    }
  }
  .intro {
    @include grid(1fr, 30px);
    &__text {
      margin: 50px;
    }
    &__container {
      grid-row: 2;
    }
  }
  .text {
    margin: 100px 0;
    padding: 10px;
    height: 550px;
    &__paragraph {
      font-size: 2.5rem;
    }
  }
  .services {
    &__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  .about {
    &__image {
      max-width: 700px;
      width: 100%;
      margin: 0 auto;
      &--circleOne {
        position: absolute;
        width: 250px;
        z-index: -1;
        left: 80px;
        top: 30px;
      }
      &--circleTwo {
        position: absolute;
        width: 100px;
        z-index: -1;
        top: 150px;
        right: 140px;
      }
    }
    &__paragraph {
      font-size: 2rem;
      line-height: 25px;
    }
  }
  .contact {
    grid-template-columns: 1fr;
    &__container {
      text-align: center;
    }
    &__input {
      max-width: 500px;
    }
    &__image {
      width: 400px;
      &--circleOne {
        position: absolute;
        width: 120px;
        z-index: -1;
        right: 60px;
        top: 50px;
      }
      &--circleTwo {
        position: absolute;
        width: 250px;
        z-index: -1;
        bottom: 20px;
        right: 80px;
      }
    }
  }
}
@include media(sm) {
  .hero {
    &__text {
      -webkit-text-stroke: 2px var(--primary-color);
      color: transparent;
      margin-left: 30px;
      &--one {
        font-size: 10rem;
      }
      &--two {
        font-size: 10rem;
        margin-top: -60px;
      }
      &--three {
        font-size: 10rem;
        margin-top: -70px;
        margin-bottom: 20px;
      }
    }
    &__image {
      &--circleOne {
        position: absolute;

        width: 200px;
        z-index: -1;
        left: -25px;
        top: 40px;
      }
      &--circleTwo {
        position: absolute;
        max-width: 150px;
        z-index: 1;
        right: 30px;
        bottom: 0px;
      }
    }
  }
  .features {
    &__text {
      margin: 0 20px;
    }
  }
  .services {
    &__container {
      grid-template-columns: 1fr;
      @include flex(center);
      flex-direction: column;
    }
  }
  .about {
    &__image {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      &--circleOne {
        position: absolute;
        width: 250px;
        z-index: -1;
        left: 80px;
        top: 30px;
      }
      &--circleTwo {
        position: absolute;
        width: 100px;
        z-index: -1;
        top: 50px;
        right: 20px;
      }
    }
    &__paragraph {
      font-size: 2rem;
      line-height: 25px;
    }
  }
  .contact {
    &__title {
      padding: 0 50px;
      text-align: center;
      font-size: 2.8rem;
    }
    &__input {
      width: 390px;
      padding: 15px 10px;
    }
    &__button {
      width: 390px;
      font-size: 1.8rem;
    }
    &__image {
      width: 350px;
      &--circleOne {
        position: absolute;
        width: 80px;
        z-index: -1;
        right: 60px;
        top: 50px;
      }
      &--circleTwo {
        position: absolute;
        width: 150px;
        z-index: -1;
        bottom: 20px;
        right: 80px;
      }
    }
  }
}

@include media(xs) {
  .hero {
    &__text {
      -webkit-text-stroke: 2px var(--primary-color);
      color: transparent;
      margin-left: 30px;
      &--one {
        font-size: 8.5rem;
      }
      &--two {
        font-size: 8.5rem;
        margin-top: -40px;
      }
      &--three {
        font-size: 8.5rem;
        margin-top: -40px;
        margin-bottom: 20px;
      }
    }
    &__image {
      &--circleOne {
        position: absolute;

        width: 200px;
        z-index: -1;
        left: -25px;
        top: 40px;
      }
      &--circleTwo {
        position: absolute;
        max-width: 150px;
        z-index: 1;
        right: 30px;
        bottom: 0px;
      }
    }
  }
  .intro {
    &__paragraph {
      font-size: 2rem;
      line-height: 27px;
    }
  }
  .features {
    &__text {
      margin: 0 20px;
    }
  }
  .services {
    &__section {
      @include grid(1fr, 20px);
      margin: 100px 0;
    }
    &__title {
      width: 100%;
      text-align: center;
      height: 150px;
      margin: -62px 0;
      padding: 20px;
      line-height: 5rem;
    }
    &__image {
      display: none;
    }
    &__container {
      grid-template-columns: 1fr;
      @include flex(center);
      flex-direction: column;
    }
  }
  .about {
    &__container {
      margin: 0 5px;
    }
    &__image {
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
      &--circleOne {
        position: absolute;
        width: 150px;
        z-index: -1;
        left: 80px;
        top: 30px;
      }
      &--circleTwo {
        position: absolute;
        width: 80px;
        z-index: -1;
        top: 50px;
        right: 0px;
      }
    }
    &__paragraph {
      font-size: 2rem;
      line-height: 27px;
    }
  }
  .contact {
    padding: 0 10px;
    &__input {
      width: 330px;
    }
    &__title {
      padding: 0 20px;
      text-align: left;
      font-size: 2.8rem;
    }
    &__button {
      width: 330px;
      font-size: 1.6rem;
    }
  }
}
