@import "./Mixins.scss";
.menu {
  &__container {
    @include flex(space-between);
    max-width: 1555px;
    width: 100%;
    margin: auto;
    padding: 30px 20px;
  }
  &__logo {
    width: 110px;
    margin-left: 10px;
  }
  &__list {
    @include flex(center);
    list-style: none;
  }
  &__links {
    margin: 0 15px;
    font-size: 1.6rem;
    cursor: pointer;
    &--text {
      &:hover {
        border-bottom: 1px solid var(--primary-color);
      }
    }
    &--button {
      background-color: var(--primary-color);
      border-radius: 20px;
      border: none;
      color: white;
      padding: 7px 30px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);

      &:hover {
        background-color: white;
        color: var(--primary-color);
      }
    }
    &--icon {
      display: none;
    }
    &--linkedin {
      background: transparent;
      border: none;
      margin-left: -20px;
    }
  }
  &__button {
    background: transparent;
    border: none;
    width: 30px;
  }
}

.mobile {
  position: absolute;
  top: 140px;
  background-color: white;
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  text-align: center;
  &__list {
    flex-direction: column;
    list-style: none;
    height: 80vh;
    margin-top: 70px;
    margin-left: 110px;
    text-align: left;
  }
  &__links {
    margin: 40px 0;
    font-size: 1.8rem;
    &--button {
      background-color: var(--primary-color);
      border-radius: 20px;
      border: none;
      color: white;
      padding: 7px 30px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      &:hover {
        background-color: white;
        color: var(--primary-color);
      }
    }
    &--linkedin {
      background: transparent;
      border: none;
      margin-left: -20px;
    }
  }
}

@include media(md) {
  .menu {
    &__links {
      display: none;
      &--icon {
        display: block;
      }
    }
  }
}
