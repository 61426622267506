@import "./Mixins.scss";
.footer {
  border-top: 1px solid #dbdbdb;
  padding: 50px;
  &__container {
    @include grid(2fr 1fr, 30px);
  }
  &__logo {
    width: 120px;
  }
  &__list {
    @include grid(1fr 1fr, 10px);
    text-align: right;
    list-style: none;
  }
  &__item {
    font-size: 1.4rem;
  }
}
@include media(md) {
  .footer {
    &__container {
      @include grid(1fr, 30px);
    }
  }
}
